// import React, { useState } from 'react';
// import useAuth from '../../hooks/useAuth';
// import { useNavigate } from 'react-router-dom';

// const Login = () => {
//     const { login, loading } = useAuth();
//     const [username, setUsername] = useState('');
//     const [password, setPassword] = useState('');
//     const navigate = useNavigate();

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             await login(username, password);
//             navigate('/dashboard'); // Redirect to a protected route on successful login
//         } catch (error) {
//             console.error('Login failed:', error);
//             // Handle login error (e.g., show an error message)
//         }
//     };

//     return (
//         <div className="login-container">
//             <form onSubmit={handleSubmit}>
//                 <label>
//                     Username:
//                     <input
//                         type="text"
//                         value={username}
//                         onChange={(e) => setUsername(e.target.value)}
//                     />
//                 </label>
//                 <label>
//                     Password:
//                     <input
//                         type="password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                     />
//                 </label>
//                 <button type="submit" disabled={loading}>
//                     {loading ? 'Logging in...' : 'Login'}
//                 </button>
//             </form>
//             {loading && (
//                 <div>loading...</div>
//             )}
//         </div>
//     );
// };

// export default Login;

import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";

const Login = () => {
  const { login, loading } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(username, password);
      navigate("/calculation");
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.detail || "Login failed";
        setError(errorMessage);
      } else {
        setError("An unexpected error occurred. Please try again later.");
      }
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-teal-50">
      <div className="w-full max-w-md p-8 space-y-8 bg-white shadow-xl rounded-xl">
        <h3 className="text-3xl font-bold text-center text-teal-700">Login</h3>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium text-teal-700"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-white border border-teal-300 rounded-md text-sm shadow-sm placeholder-teal-400
                       focus:outline-none focus:border-teal-500 focus:ring-1 focus:ring-teal-500
                       disabled:bg-teal-50 disabled:text-teal-500 disabled:border-teal-200 disabled:shadow-none
                       invalid:border-pink-500 invalid:text-pink-600
                       focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            />
          </div>
          <div className="relative">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-teal-700"
            >
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 block w-full pl-10 pr-3 py-2 bg-white border border-teal-300 rounded-md text-sm shadow-sm placeholder-teal-400
                           focus:outline-none focus:border-teal-500 focus:ring-1 focus:ring-teal-500
                           disabled:bg-teal-50 disabled:text-teal-500 disabled:border-teal-200 disabled:shadow-none
                           invalid:border-pink-500 invalid:text-pink-600
                           focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
              />
              <div
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 left-0 pl-3 flex items-center text-sm leading-5 cursor-pointer"
              >
                {showPassword ? (
                  <EyeOff className="h-5 w-5 text-teal-500" />
                ) : (
                  <Eye className="h-5 w-5 text-teal-500" />
                )}
              </div>
            </div>
          </div>
          <button
            type="submit"
            disabled={loading}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:bg-teal-300 disabled:cursor-not-allowed transition duration-150 ease-in-out"
          >
            {loading ? "Logging in..." : "Login"}
          </button>
        </form>

        {loading && (
          <div className="text-center text-sm text-teal-500">Loading...</div>
        )}
        {error && (
          <div className="mt-3 text-center text-sm text-red-600 bg-red-100 border border-red-400 rounded-md p-2">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;

// import React, { useState } from 'react';
// import useAuth from '../../hooks/useAuth';
// import { useNavigate } from 'react-router-dom';

// const Login = () => {
//     const { login, loading } = useAuth();
//     const [username, setUsername] = useState('');
//     const [password, setPassword] = useState('');
//     const [showPassword, setShowPassword] = useState(false);
//     const [error, setError] = useState(null);
//     const navigate = useNavigate();

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             await login(username, password);
//             navigate('/dashboard');
//         } catch (error) {
//             if (error.response && error.response.data) {
//                 const errorMessage = error.response.data.detail || 'Login failed';
//                 const errors = error.response.data.errors || {};
//                 setError(errorMessage);
//             } else {
//                 setError('An unexpected error occurred. Please try again later.');
//             }
//         }
//     };

//     return (
//         <div className="flex justify-center items-center min-h-screen bg-primary-50">
//             <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-md rounded-lg">
//                 <h3 className="text-2xl font-bold text-center">Login</h3>
//                 <form onSubmit={handleSubmit} className="space-y-4">
//                     <div>
//                         <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
//                         <input
//                             type="text"
//                             id="username"
//                             value={username}
//                             onChange={(e) => setUsername(e.target.value)}
//                             className="input-style"
//                         />
//                     </div>
//                     <div className="relative">
//                         <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
//                         <input
//                             type={showPassword ? 'text' : 'password'}
//                             id="password"
//                             value={password}
//                             onChange={(e) => setPassword(e.target.value)}
//                             className="input-style"
//                         />
//                         <div
//                             onClick={() => setShowPassword(!showPassword)}
//                             className="password-toggle"
//                         >
//                             {showPassword ? (
//                                 <i className="fas fa-eye-slash"></i>
//                             ) : (
//                                 <i className="fas fa-eye"></i>
//                             )}
//                         </div>
//                     </div>
//                     <button
//                         type="submit"
//                         disabled={loading}
//                         className="button-style"
//                     >
//                         {loading ? 'Logging in...' : 'Login'}
//                     </button>
//                 </form>
//                 {loading && (
//                     <div className="text-center text-sm text-gray-500">Loading...</div>
//                 )}
//                 {error && (
//                     <div className="error-message text-center">{error}</div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default Login;

// import React, { useState } from 'react';
// import useAuth from '../../hooks/useAuth';
// import { useNavigate } from 'react-router-dom';

// const Login = () => {
//     const { login, loading } = useAuth();
//     const [username, setUsername] = useState('');
//     const [password, setPassword] = useState('');
//     const [showPassword, setShowPassword] = useState(false);
//     const [error, setError] = useState(null);
//     const navigate = useNavigate();

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             await login(username, password);
//             navigate('/dashboard'); // Redirect to a protected route on successful login
//         } catch (error) {
//             if (error.response && error.response.data) {
//                 const errorMessage = error.response.data.detail || 'Login failed';
//                 const errors = error.response.data.errors || {};
//                 setError(errorMessage);
//                 console.error('Login failed:', errorMessage, errors);
//             } else {
//                 setError('An unexpected error occurred. Please try again later.');
//             }
//         }
//     };

//     // const handleSubmit = async (e) => {
//     //     e.preventDefault();
//     //     try {
//     //         await login(username, password);
//     //         navigate('/dashboard'); // Redirect to a protected route on successful login
//     //     } catch (error) {
//     //         setError(error.message);
//     //         console.error('Login failed:', error);
//     //         // Handle login error (e.g., show an error message)
//     //     }
//     // };

//     return (
//         <div className="flex justify-center items-center min-h-screen bg-primary-50">
//             <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-md rounded-lg">
//                 {/* <h3 className="text-2xl font-bold text-center">تسجيل الدخول</h3> */}
//                 <form onSubmit={handleSubmit} className="space-y-4">
//                     <div>
//                         <label className="block text-sm font-medium text-gray-700">اسم المستخدم</label>
//                         <input
//                             type="text"
//                             value={username}
//                             onChange={(e) => setUsername(e.target.value)}
//                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-100 focus:border-blue-100"
//                         />
//                     </div>
//                     <div className="relative">
//                         <label className="block text-sm font-medium text-gray-700">كلمة المرور</label>
//                         <input
//                             type={showPassword ? 'text' : 'password'} // Toggle input type
//                             value={password}
//                             onChange={(e) => setPassword(e.target.value)}
//                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-100 focus:border-blue-100"
//                         />
//                         {/* Eye Icon */}
//                         <div
//                             onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
//                             className="absolute left-3 bottom-3 cursor-pointer"
//                         >
//                             {showPassword ? (
//                                 <svg
//                                     xmlns="http://www.w3.org/2000/svg"
//                                     className="h-5 w-5 text-gray-500"
//                                     viewBox="0 0 24 24"
//                                     fill="none"
//                                     stroke="currentColor"
//                                     strokeWidth="2"
//                                     strokeLinecap="round"
//                                     strokeLinejoin="round"
//                                 >
//                                     <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
//                                     <circle cx="12" cy="12" r="3" />
//                                 </svg>
//                             ) : (
//                                 <svg
//                                     xmlns="http://www.w3.org/2000/svg"
//                                     className="h-5 w-5 text-gray-500"
//                                     viewBox="0 0 24 24"
//                                     fill="none"
//                                     stroke="currentColor"
//                                     strokeWidth="2"
//                                     strokeLinecap="round"
//                                     strokeLinejoin="round"
//                                 >
//                                     <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
//                                     <path d="M4.93 4.93l14.14 14.14" />
//                                 </svg>
//                             )}
//                         </div>
//                     </div>
//                     <button
//                         type="submit"
//                         disabled={loading}
//                         className="w-full py-2 px-4 btn-green"
//                     >
//                         {loading ? 'Logging in...' : 'دخول'}
//                     </button>
//                 </form>
//                 {loading && (
//                     <div className="text-center text-sm text-gray-500">Loading...</div>
//                 )}
//                 {error && (
//                     <div className='error-message text-center'>{error}</div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default Login;
