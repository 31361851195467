import axios from 'axios';

// const API_URL = 'http://localhost:8000';
const API_URL = 'http://192.168.5.91:8000';
// const API_URL = 'http://192.168.1.4:8000'
// const API_URL = 'http://185.252.235.110:8001';

const api = axios.create({
  baseURL: API_URL,
});

const setAuthToken = (token) => {
  if (token) {
    console.log("has token : ",token);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    console.log("no token");
    delete api.defaults.headers.common['Authorization'];
  }
};

export { api, setAuthToken };

export default api;









// import axios from 'axios';

// const api = axios.create({
//   baseURL: 'http://localhost:8000', // Update this to match your backend URL
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// api.interceptors.request.use(
//   config => {
//     const authTokens = JSON.parse(localStorage.getItem('authTokens'));
//     if (authTokens) {
//       config.headers.Authorization = `Bearer ${authTokens.access}`;
//     }
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

// export default api;







// import axios from 'axios';

// const api = axios.create({
//   baseURL: 'http://localhost:8000', // Update with your Django backend URL
// });

// api.interceptors.request.use((config) => {
//   const authTokens = JSON.parse(localStorage.getItem('authTokens'));
//   if (authTokens) {
//     config.headers.Authorization = `Bearer ${authTokens.access}`;
//   }
//   return config;
// });

// export default api;
