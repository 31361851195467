import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  Button,
  Input,
  Select,
  DatePicker,
  Table,
  Space,
  Popconfirm,
  Typography,
  Card,
  message,
  Collapse,
  Descriptions,
} from "antd";
import useAuth from "../hooks/useAuth";
import axios from "../services/api";
import PictureList from "./UI/PictureList";
import "./CalculationForm.css";
import moment from "moment";

const { Option } = Select;

const predefinedColors = [
  "#E6F4FF", // Light Blue
  "#FFF7E6", // Light Orange
  "#E6FFFB", // Light Teal
  "#FCE6FF", // Light Purple
  "#F0FFF0", // Light Green
  "#FFF0F5", // Lavender Blush
];

const generateRandomColor = () => {
  return predefinedColors[Math.floor(Math.random() * predefinedColors.length)];
};

const CalculationForm = () => {
  // ... (all your existing state and functions remain the same)
  const { user } = useAuth();

  const engineerId = user.engineer ? user.engineer_details.id : null;
  const graduation_date = user.engineer
    ? user.engineer_details.graduation_date
    : null;
  const graduation_date_obj = new Date(graduation_date);
  const graduate_month = graduation_date_obj.getMonth() + 1;
  const graduate_year = graduation_date_obj.getFullYear();
  const graduation_date_str = `${
    graduate_month < 10 ? `0${graduate_month}` : graduate_month
  }-${graduate_year}`;

  const [engineerNumber, setEngineerNumber] = useState("");
  const [engineerData, setEngineerData] = useState(null);

  const [workTypes, setWorkTypes] = useState([]);
  const [items, setItems] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [selectedWorkType, setSelectedWorkType] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [employeeType_cType, setEmployeeType_cType] = useState("default");
  const [employeeType_dType, setEmployeeType_dType] = useState("default");

  const [officeType, setOfficeType] = useState("");

  const [freeType, setFreeType] = useState("");

  const [abroadType, setAbroadType] = useState("");

  const [militaryType, setMilitaryType] = useState("");
  const [militaryType_aType, setMilitaryType_aType] = useState("default");

  const [additionalField, setAdditionalField] = useState(0); // State for the additional field value

  const [yearlyAdditionalFields, setYearlyAdditionalFields] = useState([]);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchWorkTypes = async () => {
      try {
        const response = await axios.get("/api/worktypes/");
        setWorkTypes(response.data);
      } catch (error) {
        console.error("Failed to fetch work types:", error);
      }
    };
    fetchWorkTypes();
  }, []);

  // Calculate the years between the selected start and end dates
  const calculateYearsInRange = () => {
    if (startDate && endDate) {
      const startYear = startDate.year();
      const endYear = endDate.year();
      const years = [];

      for (let year = startYear; year <= endYear; year++) {
        years.push({
          year,
          additionalField: "", // Each year has its own additionalField value
        });
      }

      setYearlyAdditionalFields(years);
    }
  };

  // Trigger the calculation of years when startDate or endDate is changed
  useEffect(() => {
    calculateYearsInRange();
  }, [startDate, endDate]);

  const handleYearInputChange = (year, value) => {
    setYearlyAdditionalFields((prev) =>
      prev.map((item) =>
        item.year === year ? { ...item, additionalField: value } : item
      )
    );
    console.log("Yearly Additional Fields : ", yearlyAdditionalFields);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `/api/engineers/central_number/${engineerNumber}/`
      );
      setEngineerData(response.data); // Set the retrieved engineer data
      message.success("Engineer found!");
      setTableData([]);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        message.error("Engineer not found!");
      } else {
        message.error("An error occurred while fetching the engineer.");
      }
      setEngineerData(null); // Clear the engineer data if not found
    }
  };

  const handleAddItem = () => {
    setTableData([]);
    console.log("startDate: ", startDate);
    console.log("endDate: ", endDate);
    console.log("selectedWorkType: ", selectedWorkType);
    console.log("officeType: ", officeType);
    console.log("yearlyAdditionalFields: ", yearlyAdditionalFields);

    if (
      startDate &&
      endDate &&
      selectedWorkType &&
      officeType == "office_a" &&
      yearlyAdditionalFields.length > 0
    ) {
      const newItems = yearlyAdditionalFields.map((yearItem) => ({
        year: yearItem.year,
        additionalField: yearItem.additionalField || 0,
        startDate:
          yearItem.year === startDate.year
            ? startDate.format("YYYY-MM-DD")
            : moment(`${yearItem.year}-01-01`).format("YYYY-MM-DD"),
        endDate:
          yearItem.year === endDate.year
            ? endDate.format("YYYY-MM-DD")
            : moment(`${yearItem.year}-12-31`).format("YYYY-MM-DD"),
        workType: selectedWorkType,
        officeType: officeType,
      }));

      setItems([...items, ...newItems]);
      setStartDate(null);
      setEndDate(null);
      setSelectedWorkType("");
      setOfficeType("");
      setYearlyAdditionalFields([]);
      console.log("CASE_2 ----> Items : ", items);
    } else if (startDate && endDate && selectedWorkType) {
      setItems([
        ...items,
        {
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
          workType: selectedWorkType,
          employeeType: employeeType,
          employeeType_cType: employeeType_cType,
          employeeType_dType: employeeType_dType,
          officeType: officeType,
          freeType: freeType,
          abroadType: abroadType,
          militaryType: militaryType,
          militaryType_aType: militaryType_aType,
          additionalField: additionalField || 0,
        },
      ]);
      console.log("Adddddddddddddddddddithional :  ", additionalField);
      console.log("CASE_1 ----> Items : ", items);
      setStartDate(null);
      setEndDate(null);
      setSelectedWorkType("");
      setEmployeeType("");
      setEmployeeType_cType("default");
      setEmployeeType_dType("default");
      setOfficeType("");
      setFreeType("");
      setAbroadType("");
      setMilitaryType("");
      setMilitaryType_aType("default");
      setAdditionalField(0); // Reset additional field after adding
      console.log("CASE_1 ----> Items : ", items);
    }

    console.log("###### Items : ", items);
  };

  const handleRemoveItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  // Extract the graduation date and format it to MM-YYYY
  const formatGraduationDate = (date) => {
    const dateObj = new Date(date);
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2); // Get the month and pad with leading zero
    const year = dateObj.getFullYear();
    return `${month}-${year}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Format the date before making the request
    const formattedGraduationDate = formatGraduationDate(
      engineerData.graduation_date
    );

    for (const item of items) {
      const requestData = {
        startMonth: moment(item.startDate).format("MM-YYYY"), // Format start date as MM-YYYY
        endMonth: moment(item.endDate).format("MM-YYYY"), // Format end date as MM-YYYY
        // graduationDate: graduation_date_str,
        graduationDate: formattedGraduationDate,
        engineer_id: engineerId,
        additionalField: item.additionalField || 0, // Ensure additionalField is provided
        employeeType: item.employeeType || "", // Ensure employeeType is provided
        employeeType_cType: item.employeeType_cType || "", // Ensure employeeType_cType is provided
        employeeType_dType: item.employeeType_dType || "", // Ensure employeeType_dType is provided
        officeType: item.officeType || "", // Ensure officeType is provided
        freeType: item.freeType || "", // Ensure freeType is provided
        abroadType: item.abroadType || "",
        militaryType: item.militaryType || "",
        militaryType_aType: item.militaryType_aType || "",
      };

      console.log("item requstData : ", requestData);

      try {
        let response;
        if (item.workType == 1) {
          response = await axios.post("/api/officecalc2/", requestData);
        } else if (item.workType == 2) {
          response = await axios.post("/api/employeecalc2/", requestData);
        } else if (item.workType == 3) {
          response = await axios.post("/api/privatesectorcalc2/", requestData);
        } else if (item.workType == 5) {
          response = await axios.post("/api/abroadcalc2/", requestData);
        } else if (item.workType == 6) {
          response = await axios.post("/api/militarycalc2/", requestData);
        }

        // setTableData(() => [...response.data.data]);
        setTableData((prevData) => [...prevData, ...response.data.data]);
      } catch (error) {
        console.error(
          `Failed to fetch data for work type ${item.workType}:`,
          error
        );
      }
    }
    // Empty the items array
    // items = [];
  };

  const columns = [
    { title: "من تاريخ", dataIndex: "startDate", key: "startDate" },
    { title: "إلى تاريخ", dataIndex: "endDate", key: "endDate" },
    // { title: "نوع العمل", dataIndex: "workType", key: "workType" },
    {
      title: "نوع العمل",
      dataIndex: "workType",
      key: "workType",
      render: (text, record) => {
        switch (record.workType) {
          case 1:
            return "موظف";
          case 2:
            return "مكاتب";
          case 3:
            return "عمل حر";
          case 5:
            return "خارج القطر";
          case 6:
            return "عمل حر";
          default:
            return "نوع العمل"; // Default case if workType is not 1, 2, or 3
        }
      },
    },
    {
      title: "الحالة",
      dataIndex: "employeeType",
      key: "employeeType",
      render: (text, record) => (
        <span>
          {record.employeeType} | {record.officeType} | {record.militaryType} |{" "}
          {record.freeType}
        </span>
      ),
    },
    {
      title: "نوع الحالة",
      dataIndex: "employeeType_cType",
      key: "employeeType_cType",
    },
    {
      title: "الراتب | التوريد",
      dataIndex: "additionalField",
      key: "additionalField",
    },
    {
      title: "إزالة",
      key: "action",
      render: (_, record, index) => (
        <Popconfirm
          title="هل أنت متأكد من حذف هذا العنصر؟"
          onConfirm={() => handleRemoveItem(index)}
        >
          <a>إزالة</a>
        </Popconfirm>
      ),
    },
  ];

  // const workTypeText = {
  //   1: "مكاتب",
  //   2: "موظف",
  //   3: "عمل حر",
  //   4: "تعهدات",
  //   5: "خارج القطر",
  // };

  const tableColumns = [
    { title: "السنة", dataIndex: "year", key: "year" },
    { title: "الشهر", dataIndex: "month", key: "month" },
    { title: "العمر الهندسي", dataIndex: "eng_age", key: "eng_age" },
    {
      title: "الدخل السنوي",
      dataIndex: "year_income",
      key: "year_income",
      render: (text, record) => (
        <span>
          {record.year_income} | 6% = {parseInt((record.year_income * 6) / 100)}
        </span>
      ),
    },
    { title: "العائدات", dataIndex: "minimum", key: "minimum" },
    // {
    //   title: "minimum",
    //   dataIndex: "year_minimum",
    //   key: "year_minimum",
    //   render: (text, record) => (
    //     <span>
    //       {record.year_minimum} | {record.present_year_minimum}
    //     </span>
    //   ),
    // },
    // {
    //   title: "fine_prcnt",
    //   dataIndex: "fine_percentage",
    //   key: "fine_percentage",
    //   render: (text, record) => (
    //     <span>{parseInt(record.fine_percentage, 10)}%</span>
    //   ),
    // },

    // {
    //   title: "inc_prcnt",
    //   dataIndex: "old_percentage",
    //   key: "old_percentage",
    //   render: (text, record) => (
    //     <span>{parseInt(record.old_percentage, 10)}%</span>
    //   ),
    // },
    {
      title: "flag",
      dataIndex: "flag",
      key: "flag",
      render: (text, record) => {
        let flagText = "";
        if (record.month == 12) {
          switch (record.flag) {
            case 0:
              flagText = "";
              break;
            case 1:
              flagText = "لا يوجد توريدات";
              break;
            case 2:
              flagText = "التوريد أقل من الحد الأدنى";
              break;
            case 3:
              flagText = "التوريد أكثر من الحد الأدنى";
              break;
            case 4:
              flagText = "موظف لم تقم دائرته بالحسم,الراتب أعلى";
              break;
            case 5:
              flagText = "قطاع خاص مسجل بالتأمينات,الراتب أعلى";
              break;
            case 6:
              flagText = "موظف لم تقم دائرته بالحسم,العمر الهندسي أعلى";
              break;
            case 7:
              flagText = "قطاع خاص مسجل بالتأمينات,العمر الهندسي أعلى";
              break;
            case 8:
              flagText = "إجازة,قطاع خاص,الراتب أعلى";
              break;
            case 9:
              flagText = "إجازة,قطاع خاص,العمر الهندسي أعلى";
              break;
            case 10:
              flagText = "قبل الانتساب,بريء الذمة";
              break;
            case 11:
              flagText = "قبل الانتساب ,نقص في العائدات المحولة";
              break;
            case 12:
              flagText = "قبل الانتساب,عدم تحويل العائدات للخزانة";
              break;
            case 13:
              flagText = "إجازة,عمل حر,الراتب أعلى";
              break;
            case 14:
              flagText = "إجازة,صاحب منشأة,الراتب أعلى";
              break;
            case 15:
              flagText = "إجازة,عمل حر,العمر الهندسي أعلى";
              break;
            case 16:
              flagText = "إجازة,صاحب منشأة,العمر الهندسي أعلى";
              break;
            case 17:
              flagText = "إجازة,خارج القطر";
              break;
            case 18:
              flagText = "عمل حر ,قبل الانتساب";
              break;
            case 19:
              flagText = "عمل حر ,بعد الانتساب";
              break;
            case 20:
              flagText = "خارج القطر,منتهية اقامته";
              break;
            case 21:
              flagText = " خارج القطر ,مقيم حالياََ في بلد الاغتراب";
              break;
            case 22:
              flagText = " خدمة علم";
              break;
            default:
              flagText = ""; // or some default text
          }
        }
        return <span>{flagText}</span>;
      },
    },
    { title: "type", dataIndex: "type", key: "type" },
  ];

  // const updatedTableData = tableData.map((row) => {
  //   // Ensure minimum is a number before any operation
  //   const minimumValue = Number(row.minimum);

  //   if (
  //     row.type === "office_a" ||
  //     row.type === "abroad_a" ||
  //     row.type === "abroad_b"
  //   ) {
  //     if (row.month >= 1 && row.month <= 11) {
  //       return { ...row, minimum: 0 };
  //     } else if (row.month == 12) {
  //       return { ...row, minimum: minimumValue * 12 };
  //     }
  //   }
  //   return row;
  // });

  const updatedTableData = tableData.map((row) => {
    // Ensure minimum is a number before any operation
    const minimumValue = Number(row.minimum);

    // Find all rows for the current year
    const yearRows = tableData.filter((data) => data.year === row.year);

    // Check if the year has all 12 months
    const hasAllMonths = yearRows.length === 12;

    if (
      row.type === "office_a" ||
      row.type === "abroad_a" ||
      row.type === "abroad_b"
    ) {
      if (hasAllMonths) {
        if (row.month >= 1 && row.month <= 11) {
          return { ...row, minimum: 0 };
        } else if (row.month == 12) {
          return { ...row, minimum: minimumValue * 12 };
        }
      }
    }

    // If the year doesn't have all 12 months, return the original row
    return row;
  });

  const totalYields = useMemo(() => {
    return updatedTableData.reduce(
      (acc, row) => acc + parseFloat(row.minimum || 0),
      0
    );
  }, [updatedTableData]);

  // Map each year to a unique random color
  const yearColorMap = useMemo(() => {
    const colorMap = {};
    updatedTableData.forEach((row) => {
      if (!colorMap[row.year]) {
        colorMap[row.year] = generateRandomColor();
      }
    });
    return colorMap;
  }, [updatedTableData]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gradient-to-b from-slate-200 rounded-2xl to-white min-h-screen">
      <h1 className="text-2xl ps-4 font-bold text-blue-800 mb-12 text-start">
        طلب تثبيت مزاولة
      </h1>

      <form onSubmit={handleSubmit} className="space-y-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 cairo-font">
          <div className="bg-white p-6 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl">
            <Typography.Text
              strong
              className="block mb-3 text-blue-700 text-lg cairo-font"
            >
              رقم المركزي:
            </Typography.Text>
            <div className="flex items-center space-x-4">
              <Input
                value={engineerNumber}
                onChange={(e) => setEngineerNumber(e.target.value)}
                onPressEnter={handleSearch}
                className="flex-grow text-lg cairo-font"
                placeholder="أدخل الرقم المركزي"
              />
              <div style={{ maxWidth: "10px" }}></div>
              <Button
                onClick={handleSearch}
                type="primary"
                className="bg-blue-500 ms-3 hover:bg-blue-600 text-white text-lg h-10 cairo-font"
              >
                بحث
              </Button>
            </div>
            <Typography.Text
              type="secondary"
              className="mt-3 block text-sm cairo-font"
            >
              اختر احد هذه الارقام (1,2,3,4,5,6,7,8,9,10,12)
            </Typography.Text>
          </div>

          {engineerData && (
            <div className="col-span-full">
              <Collapse
                defaultActiveKey={["1"]}
                className="bg-white shadow-lg rounded-xl overflow-hidden"
              >
                <Collapse.Panel
                  header={
                    <span className="text-lg font-semibold cairo-font">
                      معلومات المهندس
                    </span>
                  }
                  key="1"
                  className="bg-blue-50"
                >
                  <Descriptions
                    bordered
                    column={{ xs: 1, sm: 2, md: 3 }}
                    className="bg-white cairo-font"
                    labelStyle={{ fontWeight: "bold" }}
                  >
                    {/* ... (Descriptions items remain the same) */}
                    <Descriptions.Item label="الرقم المركزي">
                      {engineerData.central_number}
                    </Descriptions.Item>
                    <Descriptions.Item label="الرقم النقابي">
                      {engineerData.engineer_number}
                    </Descriptions.Item>
                    <Descriptions.Item label="الفرع">
                      {engineerData.branch_detail
                        ? engineerData.branch_detail.branch_name
                        : engineerData.branch}
                    </Descriptions.Item>
                    <Descriptions.Item label="الفرع الرئيسي">
                      {engineerData.home_branch_detail
                        ? engineerData.home_branch_detail.branch_name
                        : engineerData.home_branch}
                    </Descriptions.Item>
                    <Descriptions.Item label="الاسم والكنية">
                      {engineerData.first_name} {engineerData.last_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="اسم الأب">
                      {engineerData.father_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="اسم الأم">
                      {engineerData.mother_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="تاريخ التخرج">
                      {engineerData.graduation_date}
                    </Descriptions.Item>
                    <Descriptions.Item label="تاريخ الانتساب">
                      {engineerData.join_date}
                    </Descriptions.Item>
                    <Descriptions.Item label="تاريخ الميلاد">
                      {engineerData.date_of_birth}
                    </Descriptions.Item>
                    <Descriptions.Item label="الجنس">
                      {engineerData.gender ? "ذكر" : "أنثى"}
                    </Descriptions.Item>
                  </Descriptions>
                </Collapse.Panel>
              </Collapse>
            </div>
          )}
        </div>

        <hr/>

        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 ">
          <div className="bg-white p-6 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl">
            <Typography.Text
              strong
              className="block mb-3 text-blue-700 text-lg cairo-font"
            >
              من تاريخ:
            </Typography.Text>
            <DatePicker
              value={startDate}
              onChange={setStartDate}
              format={{
                format: "YYYY-MM-DD",
                type: "mask",
              }}
              className="w-full text-lg cairo-font"
            />
          </div>
          <div className="bg-white p-6 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl">
            <Typography.Text
              strong
              className="block mb-3 text-blue-700 text-lg cairo-font"
            >
              إلى تاريخ:
            </Typography.Text>
            <DatePicker
              value={endDate}
              onChange={setEndDate}
              format={{
                format: "YYYY-MM-DD",
                type: "mask",
              }}
              className="w-full text-lg cairo-font"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* ... (All your select fields go here, styled similarly to the date pickers above) */}
          <div className="bg-white p-4 rounded-lg shadow-md">
            <Typography.Text strong className="block mb-2 cairo-font">
              نوع العمل :
            </Typography.Text>
            <Select
              value={selectedWorkType}
              onChange={setSelectedWorkType}
              className="w-full cairo-font"
            >
              <Option className="cairo-font" value="">
                اختر نوع العمل
              </Option>
              {workTypes.map((workType) => (
                <Option
                  className="cairo-font"
                  key={workType.id}
                  value={workType.id}
                >
                  {workType.type}
                </Option>
              ))}
            </Select>
          </div>

          {/* ... (Conditional rendering for other select fields) */}

          {/* Example of conditional rendering */}
          {selectedWorkType == 6 && (
            <div className="bg-white p-4 rounded-lg shadow-md">
              <Typography.Text strong className="block mb-2 cairo-font">
                الفترة:
              </Typography.Text>
              <Select
                value={militaryType}
                onChange={setMilitaryType}
                className="w-full cairo-font"
              >
                <Option value="">اختر الفترة </Option>
                <Option value="military_a">قبل الانتساب</Option>
                <Option value="military_b">بعد الانتساب</Option>
              </Select>
            </div>
          )}

          {selectedWorkType == 6 && militaryType == "military_a" && (
            <div className="bg-white p-4 rounded-lg shadow-md cairo-font">
              <Typography.Text strong className="block mb-2">
                الحالة:
              </Typography.Text>
              <Select
                value={militaryType_aType}
                onChange={setMilitaryType_aType}
                className="w-full cairo-font"
              >
                <Option value="default">اختر الحالة </Option>
                <Option value="military_a_a">
                  خدمة علم بعد التخرج مباشرة تلاها عمل وظيفي
                </Option>
                <Option value="military_a_b">
                  خدمة علم بعد التخرج مباشرة تلاها عمل حر
                </Option>
                <Option value="military_a_c">خدمة علم قبل وبعد الانتساب</Option>
              </Select>
            </div>
          )}
          {selectedWorkType == 5 && (
            <div className="bg-white p-4 rounded-lg shadow-md">
              <Typography.Text strong className="block mb-2 cairo-font">
                الحالة:
              </Typography.Text>
              <Select
                value={abroadType}
                onChange={setAbroadType}
                className="w-full cairo-font"
              >
                <Option value="">اختر الحالة </Option>
                <Option value="abroad_a">المهندس المنتهية إقامته</Option>
                <Option value="abroad_b">
                  المهندس المقيم حايا في بلد الاغتراب{" "}
                </Option>
              </Select>
            </div>
          )}

          {selectedWorkType == 3 && (
            <div className="bg-white p-4 rounded-lg shadow-md">
              <Typography.Text strong className="block mb-2 cairo-font">
                الحالة:
              </Typography.Text>
              <Select
                value={freeType}
                onChange={setFreeType}
                className="w-full cairo-font"
              >
                <Option value="">اختر الحالة </Option>
                <Option value="free_a">قبل الانتساب</Option>
                <Option value="free_b">بعد الانتساب </Option>
              </Select>
            </div>
          )}

          {selectedWorkType == 1 && (
            <div className="bg-white p-4 rounded-lg shadow-md">
              <Typography.Text strong className="block mb-2 cairo-font">
                حالة صاحب المكتب:
              </Typography.Text>
              <Select
                value={officeType}
                onChange={setOfficeType}
                className="w-full"
              >
                <Option value="">اختر حالة صاحب المكتب</Option>
                <Option value="office_a">مهندس له توريدات</Option>
                <Option value="office_b">مهندس ليس له توريدات </Option>
              </Select>
            </div>
          )}

          {selectedWorkType == 2 && (
            <div className="bg-white p-4 rounded-lg shadow-md">
              <Typography.Text strong className="block mb-2 cairo-font">
                حالة الموظف:
              </Typography.Text>
              <Select
                value={employeeType}
                onChange={setEmployeeType}
                className="w-full"
              >
                <Option value="">اختر نوع الموظف</Option>
                <Option value="employee_d">موظف فترة ما قبل الانتساب </Option>
                <Option value="employee_a">
                  موظف لم تقم دائرته بحسم العائدات التقاعدية عنه
                </Option>
                <Option value="employee_b">
                  موظف لدى القطاع الخاص مسجل بالتأمينات
                </Option>
                <Option value="employee_c">موظف حاصل على اجازة بلا اجر</Option>
              </Select>
            </div>
          )}

          {selectedWorkType == 2 && employeeType === "employee_d" && (
            <div className="bg-white p-4 rounded-lg shadow-md">
              <Typography.Text strong className="block mb-2 cairo-font">
                الحالة:
              </Typography.Text>
              <Select
                value={employeeType_dType}
                onChange={setEmployeeType_dType}
                className="w-full"
              >
                <Option value="default">اختر الحالة</Option>
                <Option value="employee_d_a">
                  في حال وجود أمانات لدى خزانة التقاعد باسم المهندس عن كامل
                  المدة المراد تثبيتها
                </Option>
                <Option value="employee_d_b">
                  في حال وجود نقص في العائدات المحولة من الجهة التي يعمل بها{" "}
                </Option>
                <Option value="employee_d_c">
                  في حال عدم تحويل العائدات للخزانة من قبل الجهة التي يعمل بها{" "}
                </Option>
              </Select>
            </div>
          )}

          {selectedWorkType == 2 && employeeType === "employee_c" && (
            <div className="bg-white p-4 rounded-lg shadow-md">
              <Typography.Text strong className="block mb-2 cairo-font">
                نوع العمل فترة الإجازة:
              </Typography.Text>
              <Select
                value={employeeType_cType}
                onChange={setEmployeeType_cType}
                className="w-full"
              >
                <Option value="default">اختر نوع العمل</Option>
                <Option value="employee_c_a">عمل حر</Option>
                <Option value="employee_c_b">
                  عمل لدى القطاع الخاص وكان مسجلا لدى التأمينات
                </Option>
                <Option value="employee_c_c">
                  عمل لدى القطاع الخاص وهو مسجل بالتأمينات ويمتلك المنشأة,صاحب
                  المنشأة
                </Option>
                <Option value="employee_c_d">خارج القطر</Option>
              </Select>
            </div>
          )}

          {selectedWorkType == 2 &&
          !(
            employeeType_dType === "employee_d_a" ||
            employeeType_dType === "employee_d_b" ||
            employeeType_dType === "employee_d_c"
          ) &&
          !(employeeType_cType === "employee_c_d") ? (
            <div className="bg-white p-4 rounded-lg shadow-md">
              <Typography.Text strong className="block mb-2 cairo-font">
                الراتب:
              </Typography.Text>
              <Input
                type="number"
                placeholder=" أدخل الراتب الشهري خلال الفترة المحددة"
                value={additionalField}
                onChange={(e) => setAdditionalField(e.target.value)}
                className="w-full cairo-font"
              />
            </div>
          ) : null}

          {selectedWorkType == 1 && officeType === "office_a" && (
            <div className="bg-white p-4 rounded-lg shadow-md">
              <Typography.Text className="cairo-font">
                السنوات المحددة:
              </Typography.Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {yearlyAdditionalFields.map((yearItem) => (
                  <Space
                    key={yearItem.year}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text strong>{yearItem.year}</Typography.Text>
                    <Input
                      placeholder="أدخل التوريد"
                      value={yearItem.additionalField}
                      onChange={(e) =>
                        handleYearInputChange(yearItem.year, e.target.value)
                      }
                      //   style={{ marginLeft: "10px" }}
                      className="w-full cairo-font"
                    />
                  </Space>
                ))}
              </div>
            </div>
          )}
          {/* ================ */}
        </div>

        <div className="flex justify-end space-x-4">
          <Button
            type="primary"
            onClick={handleAddItem}
            className="bg-teal-700 hover:bg-teal-900 text-white text-lg h-10 px-6 cairo-font"
          >
            إضافة
          </Button>
        </div>


        <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
          

          <div className="bg-white p-8 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl flex-1">
            <Typography.Text
              strong
              className="block mb-6 text-2xl text-blue-700 cairo-font"
            >
              تفاصيل :
            </Typography.Text>
            <Card className="bg-blue-50 border-blue-200 cairo-font">
              {/* ... (Render details based on selected options) */}

              {selectedWorkType == 2 && employeeType === "employee_a" && (
                <ul className="list-disc pl-5 space-y-2">
                  <li>
                    بيان مزاولة بالمدة المراد تثبيتها مصدق من الدائرة التي يعمل
                    بها ومن فرع النقابة.
                  </li>
                  <li>بيان بالإجازات ان وجدت.</li>
                  <li>بيان بالراتب مع التعويضات.</li>
                </ul>
              )}

              {/* ... (other conditional renderings) */}

              {selectedWorkType == 2 && employeeType === "employee_a" && (
                <ul className="list-disc pl-5 space-y-2">
                  <li>
                    بيان مزاولة بالمدة المراد تثبيتها مصدق من الدائرة التي يعمل
                    بها ومن فرع النقابة.
                  </li>
                  <li>بيان بالإجازات ان وجدت.</li>
                  <li>بيان بالراتب مع التعويضات.</li>
                </ul>
              )}

              {selectedWorkType == 2 && employeeType === "employee_b" && (
                <ul className="list-disc pl-5 space-y-2">
                  <li>بيان مزاولة مختوم من الشركة التي يعمل بها.</li>
                  <li>بيان بالراتب الذي يتقاضاه.</li>
                  <li>وثيقة من التأمينات الاجتماعية يبين فيها اشتراكه.</li>
                </ul>
              )}

              {selectedWorkType == 2 && employeeType === "employee_c" && (
                <ul className="list-disc pl-5 space-y-2">
                  <li>
                    بيان مزاولة المهنة بالمدة المطلوبة مبيناََ فيه مدة الإجازة
                    وتاريخها مصدقاََ من دائرته ومن الفرع المسجل فيه.
                  </li>
                  <li>
                    جواز سفره أو كتاب من إدارة الهجرة والجوازات يبين فيه عدم
                    مغادرته القطر خلال هذه الفترة.
                  </li>
                  <li>
                    كتاب يوضح فيه المهندس العمل الذي قام به أثناء الإجازة
                    معتمداََ بقرار من مجلس الفرع.
                  </li>
                </ul>
              )}

              {selectedWorkType == 2 && employeeType === "employee_d" && (
                <ul className="list-disc pl-5 space-y-2">
                  <li>
                    بيان مزاولة بالمدة المطلوبة من الجهة التي يعمل بها ومصدق من
                    الفرع.
                  </li>
                  <li>تصوير دفتر خدمة العلم إذا كانت ضمن فترة الضم.</li>
                  <li>
                    وثيقة من التأمينات الاجتماعية للمسجلين لدى القطاع الخاص.
                  </li>
                  <li>كتاب من الجهة التي يعمل بها مصرح به راتبه الشهري.</li>
                </ul>
              )}

              {selectedWorkType == 1 && officeType === "office_a" && (
                <ul className="list-disc pl-5 space-y-2">
                  <li>
                    بيان مزاولة المهنة بالمدة المراد تثبيتها مصدق أصولاََ من
                    لجنة المكاتب الخاصة في الفرع.
                  </li>
                </ul>
              )}

              {selectedWorkType == 1 && officeType === "office_b" && (
                <ul className="list-disc pl-5 space-y-2">
                  <li>
                    بيان مزاولة المهنة بالمدة المراد تثبيتها مصدق أصولاََ من
                    لجنة المكاتب الخاصة في الفرع.
                  </li>
                  <li>
                    جواز سفر يتضمن المدة المراد تثبيتها أو كتاب من إدارة الهجرة
                    والجوازات يبين فيه عدم مغادرته القطر.
                  </li>
                </ul>
              )}

              {selectedWorkType == 3 && (
                <ul className="list-disc pl-5 space-y-2">
                  <li>
                    بيان مزاولة المهنة بالمدة المراد تثبيتها مصدق أصولاََ من
                    الفرع.
                  </li>
                  <li>
                    كتاب من الجهة التي عمل لديها مبيناََ فيه رواتبه التي
                    تقاضاها.
                  </li>
                  <li>مذكرة إدارية باعتماد هذه المدة من قبل مجلس الفرع.</li>
                  <li>
                    جواز سفره أو كتاب من إدارة الهجرة والجوازات عن هذه المدة.
                  </li>
                  <li>
                    وثيقة من التأمينات الاجتماعية توضح بأنه غير مسجل لديها.
                  </li>
                </ul>
              )}

              {selectedWorkType == 5 && abroadType === "abroad_a" && (
                <ul className="list-disc pl-5 space-y-2">
                  <li>تصريح بالفترة المطلوب تثبيتها التي قضاها خارج القطر.</li>
                  <li>وثيقة من الجهة التي كان يعمل فيها.</li>
                </ul>
              )}

              {selectedWorkType == 5 && abroadType === "abroad_b" && (
                <ul className="list-disc pl-5 space-y-2">
                  <li>تصريح بالفترة المطلوب تثبيتها التي قضاها خارج القطر.</li>
                  <li>وثيقة من الجهة التي كان يعمل فيها.</li>
                  <li>
                    كتاب بالمدة المراد تثبيتها مصدق من الجهة التي عمل بها في
                    مزاولة المهنة ومن السفارة السورية في بلد الاغتراب.
                  </li>
                </ul>
              )}

              {selectedWorkType == 6 && (
                <ul className="list-disc pl-5 space-y-2">
                  <li>بيان مزاولة بالمدة المراد تثبيتها.</li>
                  <li>إشعار ببراءة ذمة المهندس من النقابة.</li>
                  <li>صورة عن دفتر خدمة العلم المطلوبة.</li>
                </ul>
              )}
            </Card>
          </div>

          <div style={{maxWidth:'10px'}}></div>

          <div className="bg-white p-8 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl flex-1">
            <Typography.Text
              strong
              className="block mb-6 text-2xl text-blue-700 cairo-font"
            >
              الوثائق المطلوبة:
            </Typography.Text>
            <PictureList />
          </div>
        </div>

        {/* <div className="flex justify-end space-x-4">
          <Button
            type="primary"
            onClick={handleAddItem}
            className="bg-teal-700 hover:bg-teal-900 text-white text-lg h-10 px-6 cairo-font"
          >
            إضافة
          </Button>
        </div> */}

        {items.length > 0 && (
          <div className="mb-12 cairo-font">
            <h3 className="text-2xl font-semibold text-blue-700 mb-6">
              المزاولات:
            </h3>
            <div className="overflow-x-auto bg-white rounded-xl shadow-lg">
              <Table
                dataSource={items}
                columns={columns}
                rowKey={(record) => record.startDate + record.endDate}
                pagination={false}
                className="w-full cairo-font"
              />
            </div>
            <hr className="my-8 border-blue-200" />
          </div>
        )}

        <div className="flex justify-end space-x-4">
          {items.length > 0 && (
            <Button
              type="default"
              htmlType="submit"
              onClick={handleSubmit}
              className="bg-teal-700 hover:bg-teal-900 text-white text-lg h-10 px-6 cairo-font"
            >
              إحتساب
            </Button>
          )}
        </div>
      </form>

      {updatedTableData.length > 0 && (
        <div className="overflow-x-auto mt-12 cairo-font">
          <Table
            dataSource={updatedTableData}
            columns={tableColumns}
            rowKey={(record) => record.year + record.month}
            pagination={false}
            bordered
            className="w-full bg-white rounded-xl shadow-lg overflow-hidden cairo-font"
            title={() => (
              <div className="flex justify-between items-center bg-blue-100 p-6 rounded-t-xl">
                <span className="text-xl font-semibold text-blue-800 cairo-font">
                  العائدات التقاعدية
                </span>
                <span className="text-xl font-semibold text-red-600 cairo-font">
                  مجموع العائدات: {parseInt(totalYields).toLocaleString()}
                </span>
              </div>
            )}
            rowClassName={(record) => `bg-${yearColorMap[record.year]}`}
            summary={() => (
              <Table.Summary.Row className="w-full bg-blue-100">
                <Table.Summary.Cell
                  index={0}
                  colSpan={tableColumns.length - 6}
                  className="text-right font-bold text-lg p-4 cairo-font"
                >
                  مجموع العائدات
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={6}
                  className="font-bold text-lg p-4 cairo-font"
                >
                  {parseInt(totalYields).toLocaleString()}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
            // Add this scroll property
            scroll={{ x: "max-content" }} // This ensures horizontal scroll if the content exceeds the width
          />
        </div>
      )}

      <style jsx>{`
        .cairo-font .ant-table-thead > tr > th,
        .cairo-font .ant-table-tbody > tr > td,
        .cairo-font .ant-table-summary > tr > td,
        .cairo-font .ant-table-title,
        .cairo-font .ant-table-summary-row,
        .cairo-font .ant-table-cell {
          font-family: "Cairo", sans-serif !important;
        }

        ${Object.entries(yearColorMap)
          .map(
            ([year, color]) => `
    .bg-${year} {
      background-color: ${color} !important;
    }
  `
          )
          .join("")}
      `}</style>
    </div>
  );
};

export default CalculationForm;

////////////////////////                             ///////////////////////
////////////////////////                             ///////////////////////
////////////////////////                             ///////////////////////
////////////////////////                             ///////////////////////
////////////////////////                             ///////////////////////
////////////////////////                             ///////////////////////
////////////////////////                             ///////////////////////
////////////////////////                             ///////////////////////
////////////////////////                             ///////////////////////
////////////////////////                             ///////////////////////

// import React, { Fragment, useState, useEffect, useMemo } from "react";
// import {
//   Button,
//   Input,
//   Select,
//   DatePicker,
//   Table,
//   Space,
//   Popconfirm,
//   Typography,
//   Card,
//   message,
//   Collapse,
//   Descriptions,
// } from "antd";
// import useAuth from "../hooks/useAuth";
// import axios from "../services/api";
// import PictureList from "./UI/PictureList";
// import "./CalculationForm.css";
// import moment from "moment";

// const { Option } = Select;

// // const predefinedColors = [
// //   "#ADD8E6",
// //   "#F7F7F7",
// //   "#C6F7D0",
// //   "#87CEEB",
// //   "#E5E5EA",
// //   "#C7B8EA",
// // ];

// const predefinedColors = [
//   "#E6F4FF", // Light Blue
//   "#FFF7E6", // Light Orange
//   "#E6FFFB", // Light Teal
//   "#FCE6FF", // Light Purple
//   "#F0FFF0", // Light Green
//   "#FFF0F5", // Lavender Blush
// ];

// const generateRandomColor = () => {
//   return predefinedColors[Math.floor(Math.random() * predefinedColors.length)];
// };

// const CalculationForm = () => {
//   // ... (all your existing state and functions remain the same)
//   const { user } = useAuth();

//   const engineerId = user.engineer ? user.engineer_details.id : null;
//   const graduation_date = user.engineer
//     ? user.engineer_details.graduation_date
//     : null;
//   const graduation_date_obj = new Date(graduation_date);
//   const graduate_month = graduation_date_obj.getMonth() + 1;
//   const graduate_year = graduation_date_obj.getFullYear();
//   const graduation_date_str = `${
//     graduate_month < 10 ? `0${graduate_month}` : graduate_month
//   }-${graduate_year}`;

//   const [engineerNumber, setEngineerNumber] = useState("");
//   const [engineerData, setEngineerData] = useState(null);

//   const [workTypes, setWorkTypes] = useState([]);
//   const [items, setItems] = useState([]);

//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);

//   const [selectedWorkType, setSelectedWorkType] = useState("");
//   const [employeeType, setEmployeeType] = useState("");
//   const [employeeType_cType, setEmployeeType_cType] = useState("default");
//   const [employeeType_dType, setEmployeeType_dType] = useState("default");

//   const [officeType, setOfficeType] = useState("");

//   const [freeType, setFreeType] = useState("");

//   const [abroadType, setAbroadType] = useState("");

//   const [militaryType, setMilitaryType] = useState("");
//   const [militaryType_aType, setMilitaryType_aType] = useState("default");

//   const [additionalField, setAdditionalField] = useState(0); // State for the additional field value

//   const [yearlyAdditionalFields, setYearlyAdditionalFields] = useState([]);

//   const [tableData, setTableData] = useState([]);

//   useEffect(() => {
//     const fetchWorkTypes = async () => {
//       try {
//         const response = await axios.get("/api/worktypes/");
//         setWorkTypes(response.data);
//       } catch (error) {
//         console.error("Failed to fetch work types:", error);
//       }
//     };
//     fetchWorkTypes();
//   }, []);

//   // Calculate the years between the selected start and end dates
//   const calculateYearsInRange = () => {
//     if (startDate && endDate) {
//       const startYear = startDate.year();
//       const endYear = endDate.year();
//       const years = [];

//       for (let year = startYear; year <= endYear; year++) {
//         years.push({
//           year,
//           additionalField: "", // Each year has its own additionalField value
//         });
//       }

//       setYearlyAdditionalFields(years);
//     }
//   };

//   // Trigger the calculation of years when startDate or endDate is changed
//   useEffect(() => {
//     calculateYearsInRange();
//   }, [startDate, endDate]);

//   const handleYearInputChange = (year, value) => {
//     setYearlyAdditionalFields((prev) =>
//       prev.map((item) =>
//         item.year === year ? { ...item, additionalField: value } : item
//       )
//     );
//     console.log("Yearly Additional Fields : ", yearlyAdditionalFields);
//   };

//   const handleSearch = async () => {
//     try {
//       const response = await axios.get(
//         `/api/engineers/central_number/${engineerNumber}/`
//       );
//       setEngineerData(response.data); // Set the retrieved engineer data
//       message.success("Engineer found!");
//       setTableData([]);
//     } catch (error) {
//       if (error.response && error.response.status === 404) {
//         message.error("Engineer not found!");
//       } else {
//         message.error("An error occurred while fetching the engineer.");
//       }
//       setEngineerData(null); // Clear the engineer data if not found
//     }
//   };

//   const handleAddItem = () => {
//     setTableData([]);
//     console.log("startDate: ", startDate);
//     console.log("endDate: ", endDate);
//     console.log("selectedWorkType: ", selectedWorkType);
//     console.log("officeType: ", officeType);
//     console.log("yearlyAdditionalFields: ", yearlyAdditionalFields);

//     if (
//       startDate &&
//       endDate &&
//       selectedWorkType &&
//       officeType == "office_a" &&
//       yearlyAdditionalFields.length > 0
//     ) {
//       const newItems = yearlyAdditionalFields.map((yearItem) => ({
//         year: yearItem.year,
//         additionalField: yearItem.additionalField || 0,
//         startDate:
//           yearItem.year === startDate.year
//             ? startDate.format("YYYY-MM-DD")
//             : moment(`${yearItem.year}-01-01`).format("YYYY-MM-DD"),
//         endDate:
//           yearItem.year === endDate.year
//             ? endDate.format("YYYY-MM-DD")
//             : moment(`${yearItem.year}-12-31`).format("YYYY-MM-DD"),
//         workType: selectedWorkType,
//         officeType: officeType,
//       }));

//       setItems([...items, ...newItems]);
//       setStartDate(null);
//       setEndDate(null);
//       setSelectedWorkType("");
//       setOfficeType("");
//       setYearlyAdditionalFields([]);
//       console.log("CASE_2 ----> Items : ", items);
//     } else if (startDate && endDate && selectedWorkType) {
//       setItems([
//         ...items,
//         {
//           startDate: startDate.format("YYYY-MM-DD"),
//           endDate: endDate.format("YYYY-MM-DD"),
//           workType: selectedWorkType,
//           employeeType: employeeType,
//           employeeType_cType: employeeType_cType,
//           employeeType_dType: employeeType_dType,
//           officeType: officeType,
//           freeType: freeType,
//           abroadType: abroadType,
//           militaryType: militaryType,
//           militaryType_aType: militaryType_aType,
//           additionalField: additionalField || 0,
//         },
//       ]);
//       console.log("Adddddddddddddddddddithional :  ", additionalField);
//       console.log("CASE_1 ----> Items : ", items);
//       setStartDate(null);
//       setEndDate(null);
//       setSelectedWorkType("");
//       setEmployeeType("");
//       setEmployeeType_cType("default");
//       setEmployeeType_dType("default");
//       setOfficeType("");
//       setFreeType("");
//       setAbroadType("");
//       setMilitaryType("");
//       setMilitaryType_aType("default");
//       setAdditionalField(0); // Reset additional field after adding
//       console.log("CASE_1 ----> Items : ", items);
//     }

//     console.log("###### Items : ", items);
//   };

//   const handleRemoveItem = (index) => {
//     setItems(items.filter((_, i) => i !== index));
//   };

//   // Extract the graduation date and format it to MM-YYYY
//   const formatGraduationDate = (date) => {
//     const dateObj = new Date(date);
//     const month = ("0" + (dateObj.getMonth() + 1)).slice(-2); // Get the month and pad with leading zero
//     const year = dateObj.getFullYear();
//     return `${month}-${year}`;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     // Format the date before making the request
//     const formattedGraduationDate = formatGraduationDate(
//       engineerData.graduation_date
//     );

//     for (const item of items) {
//       const requestData = {
//         startMonth: moment(item.startDate).format("MM-YYYY"), // Format start date as MM-YYYY
//         endMonth: moment(item.endDate).format("MM-YYYY"), // Format end date as MM-YYYY
//         // graduationDate: graduation_date_str,
//         graduationDate: formattedGraduationDate,
//         engineer_id: engineerId,
//         additionalField: item.additionalField || 0, // Ensure additionalField is provided
//         employeeType: item.employeeType || "", // Ensure employeeType is provided
//         employeeType_cType: item.employeeType_cType || "", // Ensure employeeType_cType is provided
//         employeeType_dType: item.employeeType_dType || "", // Ensure employeeType_dType is provided
//         officeType: item.officeType || "", // Ensure officeType is provided
//         freeType: item.freeType || "", // Ensure freeType is provided
//         abroadType: item.abroadType || "",
//         militaryType: item.militaryType || "",
//         militaryType_aType: item.militaryType_aType || "",
//       };

//       console.log("item requstData : ", requestData);

//       try {
//         let response;
//         if (item.workType == 1) {
//           response = await axios.post("/api/officecalc2/", requestData);
//         } else if (item.workType == 2) {
//           response = await axios.post("/api/employeecalc2/", requestData);
//         } else if (item.workType == 3) {
//           response = await axios.post("/api/privatesectorcalc2/", requestData);
//         } else if (item.workType == 5) {
//           response = await axios.post("/api/abroadcalc2/", requestData);
//         } else if (item.workType == 6) {
//           response = await axios.post("/api/militarycalc2/", requestData);
//         }

//         // setTableData(() => [...response.data.data]);
//         setTableData((prevData) => [...prevData, ...response.data.data]);
//       } catch (error) {
//         console.error(
//           `Failed to fetch data for work type ${item.workType}:`,
//           error
//         );
//       }
//     }
//     // Empty the items array
//     // items = [];
//   };

//   const columns = [
//     { title: "من تاريخ", dataIndex: "startDate", key: "startDate" },
//     { title: "إلى تاريخ", dataIndex: "endDate", key: "endDate" },
//     { title: "نوع العمل", dataIndex: "workType", key: "workType" },
//     {
//       title: "الحالة",
//       dataIndex: "employeeType",
//       key: "employeeType",
//       render: (text, record) => (
//         <span>
//           {record.employeeType} | {record.officeType} | {record.militaryType} |{" "}
//           {record.freeType}
//         </span>
//       ),
//     },
//     {
//       title: "نوع الحالة",
//       dataIndex: "employeeType_cType",
//       key: "employeeType_cType",
//     },
//     {
//       title: "الراتب | التوريد",
//       dataIndex: "additionalField",
//       key: "additionalField",
//     },
//     {
//       title: "إزالة",
//       key: "action",
//       render: (_, record, index) => (
//         <Popconfirm
//           title="هل أنت متأكد من حذف هذا العنصر؟"
//           onConfirm={() => handleRemoveItem(index)}
//         >
//           <a>إزالة</a>
//         </Popconfirm>
//       ),
//     },
//   ];

//   // const workTypeText = {
//   //   1: "مكاتب",
//   //   2: "موظف",
//   //   3: "عمل حر",
//   //   4: "تعهدات",
//   //   5: "خارج القطر",
//   // };

//   const tableColumns = [
//     { title: "السنة", dataIndex: "year", key: "year" },
//     { title: "الشهر", dataIndex: "month", key: "month" },
//     { title: "العمر الهندسي", dataIndex: "eng_age", key: "eng_age" },
//     {
//       title: "الدخل السنوي",
//       dataIndex: "year_income",
//       key: "year_income",
//       render: (text, record) => (
//         <span>
//           {record.year_income} | 6% = {(record.year_income * 6) / 100}
//         </span>
//       ),
//     },
//     { title: "العائدات", dataIndex: "minimum", key: "minimum" },
//     {
//       title: "minimum",
//       dataIndex: "year_minimum",
//       key: "year_minimum",
//       render: (text, record) => (
//         <span>
//           {record.year_minimum} | {record.present_year_minimum}
//         </span>
//       ),
//     },
//     {
//       title: "fine_prcnt",
//       dataIndex: "fine_percentage",
//       key: "fine_percentage",
//       render: (text, record) => (
//         <span>{parseInt(record.fine_percentage, 10)}%</span>
//       ),
//     },

//     {
//       title: "inc_prcnt",
//       dataIndex: "old_percentage",
//       key: "old_percentage",
//       render: (text, record) => (
//         <span>{parseInt(record.old_percentage, 10)}%</span>
//       ),
//     },
//     {
//       title: "flag",
//       dataIndex: "flag",
//       key: "flag",
//       render: (text, record) => {
//         let flagText = "";
//         if (record.month == 12) {
//           switch (record.flag) {
//             case 0:
//               flagText = "";
//               break;
//             case 1:
//               flagText = "لا يوجد توريدات";
//               break;
//             case 2:
//               flagText = "التوريد أقل من الحد الأدنى";
//               break;
//             case 3:
//               flagText = "التوريد أكثر من الحد الأدنى";
//               break;
//             case 4:
//               flagText = "موظف لم تقم دائرته بالحسم,الراتب أعلى";
//               break;
//             case 5:
//               flagText = "قطاع خاص مسجل بالتأمينات,الراتب أعلى";
//               break;
//             case 6:
//               flagText = "موظف لم تقم دائرته بالحسم,العمر الهندسي أعلى";
//               break;
//             case 7:
//               flagText = "قطاع خاص مسجل بالتأمينات,العمر الهندسي أعلى";
//               break;
//             case 8:
//               flagText = "إجازة,قطاع خاص,الراتب أعلى";
//               break;
//             case 9:
//               flagText = "إجازة,قطاع خاص,العمر الهندسي أعلى";
//               break;
//             case 10:
//               flagText = "قبل الانتساب,بريء الذمة";
//               break;
//             case 11:
//               flagText = "قبل الانتساب ,نقص في العائدات المحولة";
//               break;
//             case 12:
//               flagText = "قبل الانتساب,عدم تحويل العائدات للخزانة";
//               break;
//             case 13:
//               flagText = "إجازة,عمل حر,الراتب أعلى";
//               break;
//             case 14:
//               flagText = "إجازة,صاحب منشأة,الراتب أعلى";
//               break;
//             case 15:
//               flagText = "إجازة,عمل حر,العمر الهندسي أعلى";
//               break;
//             case 16:
//               flagText = "إجازة,صاحب منشأة,العمر الهندسي أعلى";
//               break;
//             case 17:
//               flagText = "إجازة,خارج القطر";
//               break;
//             case 18:
//               flagText = "عمل حر ,قبل الانتساب";
//               break;
//             case 19:
//               flagText = "عمل حر ,بعد الانتساب";
//               break;
//             case 20:
//               flagText = "خارج القطر,منتهية اقامته";
//               break;
//             case 21:
//               flagText = " خارج القطر ,مقيم حالياََ في بلد الاغتراب";
//               break;
//             case 22:
//               flagText = " خدمة علم";
//               break;
//             default:
//               flagText = ""; // or some default text
//           }
//         }
//         return <span>{flagText}</span>;
//       },
//     },
//     { title: "type", dataIndex: "type", key: "type" },
//   ];

//   // const updatedTableData = tableData.map((row) => {
//   //   // Ensure minimum is a number before any operation
//   //   const minimumValue = Number(row.minimum);

//   //   if (
//   //     row.type === "office_a" ||
//   //     row.type === "abroad_a" ||
//   //     row.type === "abroad_b"
//   //   ) {
//   //     if (row.month >= 1 && row.month <= 11) {
//   //       return { ...row, minimum: 0 };
//   //     } else if (row.month == 12) {
//   //       return { ...row, minimum: minimumValue * 12 };
//   //     }
//   //   }
//   //   return row;
//   // });

//   const updatedTableData = tableData.map((row) => {
//     // Ensure minimum is a number before any operation
//     const minimumValue = Number(row.minimum);

//     // Find all rows for the current year
//     const yearRows = tableData.filter((data) => data.year === row.year);

//     // Check if the year has all 12 months
//     const hasAllMonths = yearRows.length === 12;

//     if (
//       row.type === "office_a" ||
//       row.type === "abroad_a" ||
//       row.type === "abroad_b"
//     ) {
//       if (hasAllMonths) {
//         if (row.month >= 1 && row.month <= 11) {
//           return { ...row, minimum: 0 };
//         } else if (row.month == 12) {
//           return { ...row, minimum: minimumValue * 12 };
//         }
//       }
//     }

//     // If the year doesn't have all 12 months, return the original row
//     return row;
//   });

//   const totalYields = useMemo(() => {
//     return updatedTableData.reduce(
//       (acc, row) => acc + parseFloat(row.minimum || 0),
//       0
//     );
//   }, [updatedTableData]);

//   // Map each year to a unique random color
//   const yearColorMap = useMemo(() => {
//     const colorMap = {};
//     updatedTableData.forEach((row) => {
//       if (!colorMap[row.year]) {
//         colorMap[row.year] = generateRandomColor();
//       }
//     });
//     return colorMap;
//   }, [updatedTableData]);

//   return (
//     <div className="max-w-8xl mx-auto px-4 sm:px-4 lg:px-4 py-8 bg-gradient-to-b from-blue-50 to-white">
//       <h1 className="text-4xl font-bold text-blue-800 mb-8 text-center">
//         طلب تثبيت مزاولة
//       </h1>

//       <form onSubmit={handleSubmit} className="space-y-8">
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//           <div className="bg-white p-4 rounded-lg shadow-md">
//             <Typography.Text strong className="block mb-2 text-blue-700">
//               رقم المركزي:
//             </Typography.Text>
//             <div className="flex items-center space-x-4">
//               <Input
//                 value={engineerNumber}
//                 onChange={(e) => setEngineerNumber(e.target.value)}
//                 onPressEnter={handleSearch}
//                 className="flex-grow"
//               />
//               <Button
//                 onClick={handleSearch}
//                 type="primary"
//                 className="bg-blue-500 hover:bg-blue-600"
//               >
//                 بحث
//               </Button>
//             </div>
//             <Typography.Text type="secondary" className="mt-2 block">
//               اختر احد هذه الارقام (1,2,3,4,5,6,7,8,9,10,12)
//             </Typography.Text>
//           </div>

//           {engineerData && (
//             <div className="col-span-full">
//               <Collapse
//                 defaultActiveKey={["1"]}
//                 className="bg-white shadow-md rounded-lg"
//               >
//                 <Collapse.Panel
//                   header="معلومات المهندس"
//                   key="1"
//                   className="bg-blue-50"
//                 >
//                   <Descriptions
//                     size="small"
//                     column={{ xs: 1, sm: 2, md: 3 }}
//                     bordered
//                     className="bg-white"
//                   >
//                     <Descriptions.Item label="الرقم المركزي">
//                       {engineerData.central_number}
//                     </Descriptions.Item>
//                     <Descriptions.Item label="الرقم النقابي">
//                       {engineerData.engineer_number}
//                     </Descriptions.Item>
//                     <Descriptions.Item label="الفرع">
//                       {engineerData.branch_detail
//                         ? engineerData.branch_detail.branch_name
//                         : engineerData.branch}
//                     </Descriptions.Item>
//                     <Descriptions.Item label="الفرع الرئيسي">
//                       {engineerData.home_branch_detail
//                         ? engineerData.home_branch_detail.branch_name
//                         : engineerData.home_branch}
//                     </Descriptions.Item>
//                     <Descriptions.Item label="الاسم والكنية">
//                       {engineerData.first_name} {engineerData.last_name}
//                     </Descriptions.Item>
//                     <Descriptions.Item label="اسم الأب">
//                       {engineerData.father_name}
//                     </Descriptions.Item>
//                     <Descriptions.Item label="اسم الأم">
//                       {engineerData.mother_name}
//                     </Descriptions.Item>
//                     <Descriptions.Item label="تاريخ التخرج">
//                       {engineerData.graduation_date}
//                     </Descriptions.Item>
//                     <Descriptions.Item label="تاريخ الانتساب">
//                       {engineerData.join_date}
//                     </Descriptions.Item>
//                     <Descriptions.Item label="تاريخ الميلاد">
//                       {engineerData.date_of_birth}
//                     </Descriptions.Item>
//                     <Descriptions.Item label="الجنس">
//                       {engineerData.gender ? "ذكر" : "أنثى"}
//                     </Descriptions.Item>
//                   </Descriptions>
//                 </Collapse.Panel>
//               </Collapse>
//             </div>
//           )}
//         </div>

//         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//           <div className="bg-white p-4 rounded-lg shadow-md">
//             <Typography.Text strong className="block mb-2 text-blue-700">
//               من تاريخ:
//             </Typography.Text>
//             <DatePicker
//               value={startDate}
//               onChange={setStartDate}
//               format={{
//                 format: "YYYY-MM-DD",
//                 type: "mask",
//               }}
//               className="w-full"
//             />
//           </div>
//           <div className="bg-white p-4 rounded-lg shadow-md">
//             <Typography.Text strong className="block mb-2 text-blue-700">
//               إلى تاريخ:
//             </Typography.Text>
//             <DatePicker
//               value={endDate}
//               onChange={setEndDate}
//               format={{
//                 format: "YYYY-MM-DD",
//                 type: "mask",
//               }}
//               className="w-full"
//             />
//           </div>
//         </div>

//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//           <div className="bg-white p-4 rounded-lg shadow-md">
//             <Typography.Text strong className="block mb-2">
//               نوع العمل :
//             </Typography.Text>
//             <Select
//               value={selectedWorkType}
//               onChange={setSelectedWorkType}
//               className="w-full"
//             >
//               <Option value="">اختر نوع العمل</Option>
//               {workTypes.map((workType) => (
//                 <Option key={workType.id} value={workType.id}>
//                   {workType.type}
//                 </Option>
//               ))}
//             </Select>
//           </div>

//           {/* ... (Conditional rendering for other select fields) */}

//           {/* Example of conditional rendering */}
//           {selectedWorkType == 6 && (
//             <div className="bg-white p-4 rounded-lg shadow-md">
//               <Typography.Text strong className="block mb-2">
//                 الفترة:
//               </Typography.Text>
//               <Select
//                 value={militaryType}
//                 onChange={setMilitaryType}
//                 className="w-full"
//               >
//                 <Option value="">اختر الفترة </Option>
//                 <Option value="military_a">قبل الانتساب</Option>
//                 <Option value="military_b">بعد الانتساب</Option>
//               </Select>
//             </div>
//           )}

//           {selectedWorkType == 6 && militaryType == "military_a" && (
//             <div className="bg-white p-4 rounded-lg shadow-md">
//               <Typography.Text strong className="block mb-2">
//                 الحالة:
//               </Typography.Text>
//               <Select
//                 value={militaryType_aType}
//                 onChange={setMilitaryType_aType}
//                 className="w-full"
//               >
//                 <Option value="default">اختر الحالة </Option>
//                 <Option value="military_a_a">
//                   خدمة علم بعد التخرج مباشرة تلاها عمل وظيفي
//                 </Option>
//                 <Option value="military_a_b">
//                   خدمة علم بعد التخرج مباشرة تلاها عمل حر
//                 </Option>
//                 <Option value="military_a_c">خدمة علم قبل وبعد الانتساب</Option>
//               </Select>
//             </div>
//           )}
//           {selectedWorkType == 5 && (
//             <div className="bg-white p-4 rounded-lg shadow-md">
//               <Typography.Text strong className="block mb-2">
//                 الحالة:
//               </Typography.Text>
//               <Select
//                 value={abroadType}
//                 onChange={setAbroadType}
//                 className="w-full"
//               >
//                 <Option value="">اختر الحالة </Option>
//                 <Option value="abroad_a">المهندس المنتهية إقامته</Option>
//                 <Option value="abroad_b">
//                   المهندس المقيم حايا في بلد الاغتراب{" "}
//                 </Option>
//               </Select>
//             </div>
//           )}

//           {selectedWorkType == 3 && (
//             <div className="bg-white p-4 rounded-lg shadow-md">
//               <Typography.Text strong className="block mb-2">
//                 الحالة:
//               </Typography.Text>
//               <Select
//                 value={freeType}
//                 onChange={setFreeType}
//                 className="w-full"
//               >
//                 <Option value="">اختر الحالة </Option>
//                 <Option value="free_a">قبل الانتساب</Option>
//                 <Option value="free_b">بعد الانتساب </Option>
//               </Select>
//             </div>
//           )}

//           {selectedWorkType == 1 && (
//             <div className="bg-white p-4 rounded-lg shadow-md">
//               <Typography.Text strong className="block mb-2">
//                 حالة صاحب المكتب:
//               </Typography.Text>
//               <Select
//                 value={officeType}
//                 onChange={setOfficeType}
//                 className="w-full"
//               >
//                 <Option value="">اختر حالة صاحب المكتب</Option>
//                 <Option value="office_a">مهندس له توريدات</Option>
//                 <Option value="office_b">مهندس ليس له توريدات </Option>
//               </Select>
//             </div>
//           )}

//           {selectedWorkType == 2 && (
//             <div className="bg-white p-4 rounded-lg shadow-md">
//               <Typography.Text strong className="block mb-2">
//                 حالة الموظف:
//               </Typography.Text>
//               <Select
//                 value={employeeType}
//                 onChange={setEmployeeType}
//                 className="w-full"
//               >
//                 <Option value="">اختر نوع الموظف</Option>
//                 <Option value="employee_d">موظف فترة ما قبل الانتساب </Option>
//                 <Option value="employee_a">
//                   موظف لم تقم دائرته بحسم العائدات التقاعدية عنه
//                 </Option>
//                 <Option value="employee_b">
//                   موظف لدى القطاع الخاص مسجل بالتأمينات
//                 </Option>
//                 <Option value="employee_c">موظف حاصل على اجازة بلا اجر</Option>
//               </Select>
//             </div>
//           )}

//           {selectedWorkType == 2 && employeeType === "employee_d" && (
//             <div className="bg-white p-4 rounded-lg shadow-md">
//               <Typography.Text strong className="block mb-2">
//                 الحالة:
//               </Typography.Text>
//               <Select
//                 value={employeeType_dType}
//                 onChange={setEmployeeType_dType}
//                 className="w-full"
//               >
//                 <Option value="default">اختر الحالة</Option>
//                 <Option value="employee_d_a">
//                   في حال وجود أمانات لدى خزانة التقاعد باسم المهندس عن كامل
//                   المدة المراد تثبيتها
//                 </Option>
//                 <Option value="employee_d_b">
//                   في حال وجود نقص في العائدات المحولة من الجهة التي يعمل بها{" "}
//                 </Option>
//                 <Option value="employee_d_c">
//                   في حال عدم تحويل العائدات للخزانة من قبل الجهة التي يعمل بها{" "}
//                 </Option>
//               </Select>
//             </div>
//           )}

//           {selectedWorkType == 2 && employeeType === "employee_c" && (
//             <div className="bg-white p-4 rounded-lg shadow-md">
//               <Typography.Text strong className="block mb-2">
//                 نوع العمل فترة الإجازة:
//               </Typography.Text>
//               <Select
//                 value={employeeType_cType}
//                 onChange={setEmployeeType_cType}
//                 className="w-full"
//               >
//                 <Option value="default">اختر نوع العمل</Option>
//                 <Option value="employee_c_a">عمل حر</Option>
//                 <Option value="employee_c_b">
//                   عمل لدى القطاع الخاص وكان مسجلا لدى التأمينات
//                 </Option>
//                 <Option value="employee_c_c">
//                   عمل لدى القطاع الخاص وهو مسجل بالتأمينات ويمتلك المنشأة,صاحب
//                   المنشأة
//                 </Option>
//                 <Option value="employee_c_d">خارج القطر</Option>
//               </Select>
//             </div>
//           )}

//           {selectedWorkType == 2 &&
//           !(
//             employeeType_dType === "employee_d_a" ||
//             employeeType_dType === "employee_d_b" ||
//             employeeType_dType === "employee_d_c"
//           ) &&
//           !(employeeType_cType === "employee_c_d") ? (
//             <div className="bg-white p-4 rounded-lg shadow-md">
//               <Typography.Text strong className="block mb-2">
//                 الراتب:
//               </Typography.Text>
//               <Input
//                 type="number"
//                 placeholder=" أدخل الراتب الشهري خلال الفترة المحددة"
//                 value={additionalField}
//                 onChange={(e) => setAdditionalField(e.target.value)}
//                 className="w-full"
//               />
//             </div>
//           ) : null}

//           {selectedWorkType == 1 && officeType === "office_a" && (
//             <div className="bg-white p-4 rounded-lg shadow-md">
//               <Typography.Text>السنوات المحددة:</Typography.Text>
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   gap: "10px",
//                 }}
//               >
//                 {yearlyAdditionalFields.map((yearItem) => (
//                   <Space
//                     key={yearItem.year}
//                     style={{
//                       display: "flex",
//                       flexDirection: "row",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Typography.Text strong>{yearItem.year}</Typography.Text>
//                     <Input
//                       placeholder="أدخل التوريد"
//                       value={yearItem.additionalField}
//                       onChange={(e) =>
//                         handleYearInputChange(yearItem.year, e.target.value)
//                       }
//                       //   style={{ marginLeft: "10px" }}
//                       className="w-full"
//                     />
//                   </Space>
//                 ))}
//               </div>
//             </div>
//           )}

//           {/* ... (Other conditional fields) */}
//         </div>

//         <div className="bg-white p-6 rounded-lg shadow-md">
//           <Typography.Text strong className="block mb-4 text-xl text-blue-700">
//             الوثائق المطلوبة:
//           </Typography.Text>
//           <PictureList />
//         </div>

//         <div className="bg-white p-6 rounded-lg shadow-md">
//           <Typography.Text strong className="block mb-4 text-xl text-blue-700">
//             تفاصيل :
//           </Typography.Text>
//           <Card className="bg-blue-50">
//             {/* Render details based on selected options */}
//             {selectedWorkType == 2 && employeeType === "employee_a" && (
//               <ul className="list-disc pl-5 space-y-2">
//                 <li>
//                   بيان مزاولة بالمدة المراد تثبيتها مصدق من الدائرة التي يعمل
//                   بها ومن فرع النقابة.
//                 </li>
//                 <li>بيان بالإجازات ان وجدت.</li>
//                 <li>بيان بالراتب مع التعويضات.</li>
//               </ul>
//             )}

//             {/* ... (other conditional renderings) */}

//             {selectedWorkType == 2 && employeeType === "employee_a" && (
//               <ul className="list-disc pl-5 space-y-2">
//                 <li>
//                   بيان مزاولة بالمدة المراد تثبيتها مصدق من الدائرة التي يعمل
//                   بها ومن فرع النقابة.
//                 </li>
//                 <li>بيان بالإجازات ان وجدت.</li>
//                 <li>بيان بالراتب مع التعويضات.</li>
//               </ul>
//             )}

//             {selectedWorkType == 2 && employeeType === "employee_b" && (
//               <ul className="list-disc pl-5 space-y-2">
//                 <li>بيان مزاولة مختوم من الشركة التي يعمل بها.</li>
//                 <li>بيان بالراتب الذي يتقاضاه.</li>
//                 <li>وثيقة من التأمينات الاجتماعية يبين فيها اشتراكه.</li>
//               </ul>
//             )}

//             {selectedWorkType == 2 && employeeType === "employee_c" && (
//               <ul className="list-disc pl-5 space-y-2">
//                 <li>
//                   بيان مزاولة المهنة بالمدة المطلوبة مبيناََ فيه مدة الإجازة
//                   وتاريخها مصدقاََ من دائرته ومن الفرع المسجل فيه.
//                 </li>
//                 <li>
//                   جواز سفره أو كتاب من إدارة الهجرة والجوازات يبين فيه عدم
//                   مغادرته القطر خلال هذه الفترة.
//                 </li>
//                 <li>
//                   كتاب يوضح فيه المهندس العمل الذي قام به أثناء الإجازة معتمداََ
//                   بقرار من مجلس الفرع.
//                 </li>
//               </ul>
//             )}

//             {selectedWorkType == 2 && employeeType === "employee_d" && (
//               <ul className="list-disc pl-5 space-y-2">
//                 <li>
//                   بيان مزاولة بالمدة المطلوبة من الجهة التي يعمل بها ومصدق من
//                   الفرع.
//                 </li>
//                 <li>تصوير دفتر خدمة العلم إذا كانت ضمن فترة الضم.</li>
//                 <li>
//                   وثيقة من التأمينات الاجتماعية للمسجلين لدى القطاع الخاص.
//                 </li>
//                 <li>كتاب من الجهة التي يعمل بها مصرح به راتبه الشهري.</li>
//               </ul>
//             )}

//             {selectedWorkType == 1 && officeType === "office_a" && (
//               <ul className="list-disc pl-5 space-y-2">
//                 <li>
//                   بيان مزاولة المهنة بالمدة المراد تثبيتها مصدق أصولاََ من لجنة
//                   المكاتب الخاصة في الفرع.
//                 </li>
//               </ul>
//             )}

//             {selectedWorkType == 1 && officeType === "office_b" && (
//               <ul className="list-disc pl-5 space-y-2">
//                 <li>
//                   بيان مزاولة المهنة بالمدة المراد تثبيتها مصدق أصولاََ من لجنة
//                   المكاتب الخاصة في الفرع.
//                 </li>
//                 <li>
//                   جواز سفر يتضمن المدة المراد تثبيتها أو كتاب من إدارة الهجرة
//                   والجوازات يبين فيه عدم مغادرته القطر.
//                 </li>
//               </ul>
//             )}

//             {selectedWorkType == 3 && (
//               <ul className="list-disc pl-5 space-y-2">
//                 <li>
//                   بيان مزاولة المهنة بالمدة المراد تثبيتها مصدق أصولاََ من
//                   الفرع.
//                 </li>
//                 <li>
//                   كتاب من الجهة التي عمل لديها مبيناََ فيه رواتبه التي تقاضاها.
//                 </li>
//                 <li>مذكرة إدارية باعتماد هذه المدة من قبل مجلس الفرع.</li>
//                 <li>
//                   جواز سفره أو كتاب من إدارة الهجرة والجوازات عن هذه المدة.
//                 </li>
//                 <li>وثيقة من التأمينات الاجتماعية توضح بأنه غير مسجل لديها.</li>
//               </ul>
//             )}

//             {selectedWorkType == 5 && abroadType === "abroad_a" && (
//               <ul className="list-disc pl-5 space-y-2">
//                 <li>تصريح بالفترة المطلوب تثبيتها التي قضاها خارج القطر.</li>
//                 <li>وثيقة من الجهة التي كان يعمل فيها.</li>
//               </ul>
//             )}

//             {selectedWorkType == 5 && abroadType === "abroad_b" && (
//               <ul className="list-disc pl-5 space-y-2">
//                 <li>تصريح بالفترة المطلوب تثبيتها التي قضاها خارج القطر.</li>
//                 <li>وثيقة من الجهة التي كان يعمل فيها.</li>
//                 <li>
//                   كتاب بالمدة المراد تثبيتها مصدق من الجهة التي عمل بها في
//                   مزاولة المهنة ومن السفارة السورية في بلد الاغتراب.
//                 </li>
//               </ul>
//             )}

//             {selectedWorkType == 6 && (
//               <ul className="list-disc pl-5 space-y-2">
//                 <li>بيان مزاولة بالمدة المراد تثبيتها.</li>
//                 <li>إشعار ببراءة ذمة المهندس من النقابة.</li>
//                 <li>صورة عن دفتر خدمة العلم المطلوبة.</li>
//               </ul>
//             )}
//           </Card>
//         </div>

//         <div className="flex justify-end space-x-4">
//           <Button
//             type="primary"
//             onClick={handleAddItem}
//             className="bg-green-500 hover:bg-green-600"
//           >
//             إضافة
//           </Button>
//         </div>

//         {items.length > 0 && (
//           <div className="mb-8">
//             <h3 className="text-2xl font-semibold text-blue-700 mb-4">
//               المزاولات:
//             </h3>
//             <div className="overflow-x-auto bg-white rounded-lg shadow-md">
//               <Table
//                 dataSource={items}
//                 columns={columns}
//                 rowKey={(record) => record.startDate + record.endDate}
//                 pagination={false}
//                 size="small"
//                 className="w-full"
//               />
//             </div>
//             <hr className="my-6 border-blue-200" />
//           </div>
//         )}

//         <div className="flex justify-end space-x-4">
//           {items.length > 0 && (
//             <Button
//               type="default"
//               htmlType="submit"
//               onClick={handleSubmit}
//               className="bg-blue-500 hover:bg-blue-600 text-white"
//             >
//               إحتساب
//             </Button>
//           )}
//         </div>
//       </form>

//       {updatedTableData.length > 0 && (
//         <div className="overflow-x-auto mt-8">
//           <Table
//             dataSource={updatedTableData}
//             columns={tableColumns}
//             rowKey={(record) => record.year + record.month}
//             pagination={false}
//             size="small"
//             bordered
//             className="w-full bg-white rounded-lg shadow-lg"
//             title={() => (
//               <div className="flex justify-between items-center bg-blue-100 p-4 rounded-t-lg">
//                 <span className="text-lg font-semibold text-blue-800">
//                   العائدات التقاعدية
//                 </span>
//                 <span className="text-lg font-semibold text-red-600">
//                   مجموع العائدات: {parseInt(totalYields).toLocaleString()}
//                 </span>
//               </div>
//             )}
//             rowClassName={(record) => `bg-${yearColorMap[record.year]}`}
//             summary={() => (
//               <Table.Summary.Row className="w-full bg-blue-100">
//                 <Table.Summary.Cell
//                   index={0}
//                   colSpan={tableColumns.length - 6}
//                   className="text-right font-bold w-full"
//                 >
//                   مجموع العائدات
//                 </Table.Summary.Cell>
//                 <Table.Summary.Cell index={6} className="font-bold w-full">
//                   {parseInt(totalYields).toLocaleString()}
//                 </Table.Summary.Cell>
//               </Table.Summary.Row>
//             )}
//           />
//         </div>
//       )}

//       <style jsx>{`
//         ${Object.entries(yearColorMap)
//           .map(
//             ([year, color]) => `
//               .bg-${year} {
//                 background-color: ${color} !important;
//               }
//             `
//           )
//           .join("")}
//       `}</style>
//     </div>
//   );
// };
// export default CalculationForm;
